import { Weekday } from '@/__generated__/types';
import { DateTime } from 'luxon';

export const convertTime = (timeValue: Date | DateTime | number | null, type: 'time' | 'timestamp' = 'timestamp'): number => {
    if (timeValue === null) {
        return 0;
    }

    // Handle different input types
    const time =
        timeValue instanceof DateTime
            ? timeValue
            : timeValue instanceof Date
              ? DateTime.fromJSDate(timeValue)
              : DateTime.fromMillis(timeValue);

    if (type === 'time') {
        // Convert to total minutes since midnight
        return time.hour * 60 + time.minute;
    }

    // Return timestamp in milliseconds
    return time.toMillis();
};

export const parseTime = (time: number) => {
    const formatTime = DateTime.now().startOf('day').plus({ minutes: time });
    return formatTime;
};

export const getWeekdayEnum = (weekday: string): Weekday | undefined => {
    return Object.values(Weekday).find((w) => w.toLowerCase() === weekday.toLowerCase());
};
